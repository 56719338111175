import styled from 'styled-components';

export const NavBar = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: fixed; 
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
    padding: 10px 0;
    border-top: 2px solid ${({theme}) => theme.colors.border || '#fff'};
    background: ${({theme}) => theme.colors.body || '#fff'};

    & > a{
        text-decoration: none;
        color: ${({theme}) => theme.colors.text};
       
    }
`;