import React, {useEffect, useState} from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
//APO
import { Rate } from "../../../Utils/ApiRequests";
//utils
import { useAuth } from "../../../Utils/UseContext";
//style
import { ButtonContainer, Container } from "../../../components/style/Container.styled";
import { StyledTextArea } from "../../../components/style/CustomInput.styled";
import { Row, Column } from "../../../components/style/Row.styled";
import { StyledSubmitButton } from "../../../components/style/Buttons.styled";
// components
import { CustomButton } from "../../../components/Buttons";
//icons
import { MdOutlineStarOutline, MdOutlineStar } from "react-icons/md";


const RateWorkout = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { id } = useParams();
    const {item, token} = location.state || {};
    const {state} = useAuth();
    const [stars, setStars] = useState(0);
    const [comment, setComment] = useState('');

    useEffect(() => {
        if((!token || token !== state?.token) || !item){
            navigate('/notifications');
        }
    },[])

    const Stars = (stars) => {

        const average = stars
        // Average(stars)
      
        const star = <MdOutlineStar size={35} color='yellow' />
        const starOutline = <MdOutlineStarOutline size={35} color='yellow' />
        let starList = new Array(5).fill(starOutline);
        for(let i = 0; i < Math.floor(average); i++){
            // lista.splice(i,i,star)
            starList[i] = star;
        }
  
        return starList.map((star, ind) => {
          return <div key={ind} onClick={() => setStars(ind + 1)}>{star}</div>
        });
       } 

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setComment(value);
    };

    const handleSubmit = async (comment, stars, workout_id, item_id) => {
        // stars
        // comment
        //item_id = item?.id
        // workout_id = id
        // user_id (backend)
        const response = await Rate(comment, stars, workout_id, item_id);
        if(response === 200){
            // poistetaan viesti
            navigate('/notifications')
        }
    }

    return(
        <Container direction={'column'} >
            <div style={{width: '100%', maxWidth: '400px'}}>
                <Row padding={'0'} justify={'center'} justify_mobile={'center'}>
                    <h3>Rate workout {item?.body?.name}</h3>
                </Row>
                <Column align={'center'} margin={'10px 0 10px 0'}>
                    <p>How many stars would you give the exercise?</p>
                    <Row padding={'0'} width={'200px'} >
                        {Stars(stars)}
                    </Row>
                </Column>
                <Column margin={'0 0 10px 0'}>
                    <p>Leave a comment:</p>
                    <StyledTextArea
                        placeholder="Leave a comment..."
                        name="comment"
                        value={comment}
                        onChange={handleInputChange}
                    />
                </Column>
                <ButtonContainer padding={'0'} justify={'end'}>
                    <CustomButton button={StyledSubmitButton} label={'Submit'} onClick={() => handleSubmit(comment, stars, id, item?.id) }/>
                </ButtonContainer>
            </div>
        </Container>
    )
}

export default RateWorkout