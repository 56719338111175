import { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
//Utils
import { HasRole} from "../../../Utils/Role.jsx";
import { OptimizeImage, TextMinLenCount, CapitalizeFirstLetter } from "../../../Utils/Utils.jsx";
// Icons
import { MdInfoOutline, MdFormatListBulletedAdd, MdDone} from "react-icons/md";
import { IoTrash } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp, IoMdClose } from "react-icons/io";
// API and Components
import { InsertNewWorkoutToDb } from "../../../Utils/ApiRequests.jsx";
import { SubmitButton, CustomButton } from "../../../components/Buttons.jsx";
import { ScrollView, CustomPopUp } from "../../../components/Components.jsx";
import { useData} from "../../../Utils/UseContext.jsx";
// STYLE
import { LABEL, StyledWorkoutInput, StyledTextArea, StyledCustomInput } from "../../../components/style/CustomInput.styled.jsx";
import { Row } from "../../../components/style/Row.styled.jsx";
import { WorkoutDetailsRow, WorkoutContent, WorkoutInputContainer, WorkoutInputContent, WorkoutPartContainer, Title, WorkoutRow } from "../StyledComponents.jsx";
import { Container, ButtonContainer, NativeSelect, DescriptionContainer, PreviewContainer, PreviewImage, DropzoneContainer, PlaceholderText} from "../../../components/style/Container.styled.jsx";
import { StyledHalfRoundLeft, StyledHalfRoundRight, StyledSubmitButton, StyledAddButton } from "../../../components/style/Buttons.styled.jsx";
//toast
import { toast } from 'react-toastify';




export const Dropzone = ({ roleCheck, content, setContent, accept, placeholder}) => {
    const [preview, setPreview] = useState(null);
    const [error, setError] = useState(null);

    const onDrop = useCallback(async (acceptedFiles) => {

        if (!await HasRole(roleCheck ? roleCheck : 'advanced')) {
            toast.info('This is advanced feature!')
            setError('!');
            return;
        }

        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
      
            // Check file size (e.g., limit to 5MB)
            const maxSize = 5 * 1024 * 1024 * 10;

            if (file.size > maxSize) {
              setError('File size exceeds the limit of 50MB');
              return;
            }

            // Optimoi kuva ennen esikatselua ja tallennusta
            if (file.type.startsWith('image/')) {
                const optimizedImage = await OptimizeImage(file);
                const previewUrl = URL.createObjectURL(optimizedImage);

                setPreview(previewUrl);
                setError(null);
                setContent({
                    ...content,
                    file: optimizedImage,
                    previewUrl
                });
            }else{
                const previewUrl = URL.createObjectURL(file);
                setPreview(previewUrl);
                setError(null);
                setContent({
                    ...content,
                    file,
                    previewUrl
                });
            }

        }
    }, [content, setContent]);
  
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ 
        onDrop,
        accept
     });
    
    return (
      <div>
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />

          {preview ? (
                <>
                    {content?.file !== null && content?.file.type.startsWith('video/') ? (
                        <video controls width="100px" maxHeight="100px" style={{overflow:'hidden'}}>
                            <source src={preview} type={content.file.type} />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <PreviewImage src={preview} alt="Preview" />
                    )}
                </>
                ):(<PlaceholderText>{isDragActive ? 'Drop the files here...' : placeholder}</PlaceholderText>)
            }
            {error && <p style={{ color: 'red', position: 'absolute', bottom: '10px' }}>{error}</p>}
        </DropzoneContainer>
      </div>
    );
  };

const PreviewRow = ({exercise, workout, setExerciseList}) => {
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false)
    const list = Object.values(exercise)[0];
    const key = Object.keys(exercise)[0]

    const handleOnDelete = (index) => {
        const updatedList = [...workout]
        const objIndex = workout.findIndex((k) => Object.keys(k)[0] === key)
        const handledList = Object.values(updatedList[objIndex])[0];
        
        handledList.splice(index, 1);
        if(handledList.length <= 0){
            updatedList.splice(objIndex,1)
        }
        setExerciseList(updatedList)
    }

    const Media = ({item}) => {

        if(!item.file) return;

        return(
            <>
                {item?.file !== null && item?.file.type.startsWith('video/') ? (
                    <video controls width="100px" maxHeight="100px">
                        <source src={item.previewUrl} type={item.file.type} />
                        Your browser does not support the video tag.
                    </video>)
                :
                (<img src={item.previewUrl} alt="<img>" style={{ maxWidth: '60px', maxHeight: '60px', borderRadius:5 }} />
                )}
            </>
        )
    }

    const content = list.map((item, index) => {
        return(
            <>
                <Row padding={'5px 0px 5px 0px'}>
                    <p>{item.nameOfExercise}</p>
                </Row>
                <WorkoutRow columns={!item.file && ('0fr 1fr 1fr 1fr 0.5fr')}>
                        <WorkoutContent >
                            <Media item={item}/>
                        </WorkoutContent>
                        <WorkoutContent align={'flex-start'}>
                            <p>Amount</p>
                            <Row justify={'start'}>
                                <p>{item.amount}</p>
                            </Row>
                        </WorkoutContent>
                        <WorkoutContent>
                            <p>Kierrota</p>
                            <p>{item.times}</p>
                        </WorkoutContent>
                        <WorkoutContent align={'flex-end'}>
                            <p>Paino</p>
                            <p>{item.weight}</p>
                        </WorkoutContent>
                      <WorkoutContent> 
                        <IoTrash onClick={() => handleOnDelete(index)} size={20} style={{cursor: 'pointer'}}/>              
                        {item.description !== "" && (
                          <MdInfoOutline onClick={() => setIsDescriptionVisible(!isDescriptionVisible)} size={30} color={'white'} style={{cursor: 'pointer'}}/>
                          )
                        }
                      </WorkoutContent>
                </WorkoutRow>
                <CustomPopUp content={item.description} isOpen ={isDescriptionVisible} setIsOpen={() => setIsDescriptionVisible(!isDescriptionVisible)} div={DescriptionContainer}/>

            </>
        )
    }) 
    
    return(
        <>
            <h4 style={{marginTop: 20}}>{Object.keys(exercise)[0]}</h4>
            {content}
        </>
    )
}

const FilteredList = ({data, selectedItem, setSelectedItem}) => {
    const [items, setItems] = useState(data);
    const [isOpen, setIsOpen] = useState(false);
    const [newItem, setNewItem] = useState('');
    // const [options, setOptions] = useState([
    //     {value:'', label:'Add name'},
    //     {value:'legs', label:'Legs'},
    //     {value:'full body', label:'Full body'},
    //     {value:'chest', label:'Chest'}
    // ]);
  
    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelectItem = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = items.find(option => option.value === selectedValue);
        setSelectedItem(selectedValue);
        setIsOpen(false); // Sulkee dropdownin valinnan jälkeen
      };
  
      const handleAddItem = (event) => {
        event.preventDefault();
        if (newItem.trim() !== '') {
          const newItemTrimmed = newItem.trim();
          setItems([...items, {value: newItemTrimmed, label: CapitalizeFirstLetter(newItemTrimmed)}])
          setSelectedItem(newItemTrimmed); // Asettaa uuden itemin valituksi
          setNewItem(''); // Tyhjentää input-kentän
          setIsOpen(false); // Sulkee dropdownin
        }
      };

      const handleOnChange = (value) => {
        const sanitizedValue = value.replace(new RegExp('[/]', 'g'), '');
        setNewItem(sanitizedValue)
      }

        //   const filteredData = data?.filter(item =>
        //     item.toLowerCase().includes(newItem.toLowerCase())
        //   );

      const dropdown = (options) => {
        return(
            <select style={{background: 'transparent', border: 'none', height: '5em', fontSize: '16px'}} value={selectedItem} onChange={handleSelectItem}>
                {options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
        )
    }

    return(
        <Row padding={'0'}>
                {!isOpen ?
                    <>
                        {dropdown(items)}
                        <MdFormatListBulletedAdd onClick={() => setIsOpen(!isOpen)} size={30} style={{marginRight: 5}}/>
                    </>
                    :
                    <Row justify={'center'} justify_mobile={'center'} padding={'0'}>
                        <form onSubmit={handleAddItem} style={{display: 'flex', flexDirection:'row', alignItems: 'center', width: '100%'}}>
                            <StyledCustomInput
                                height={'32px'}
                                type="text"
                                placeholder="Legs..."
                                value={newItem}
                                onChange={(e) => handleOnChange(e.target.value)}
                            />
                            <CustomButton type={'submit'} button={StyledAddButton} icon={<MdDone />} style={{width: 32, height: 32, margin: '0 5px 0 5px'}}/>
                            <CustomButton onClick={() => setIsOpen(!isOpen)} button={StyledAddButton} icon={<IoMdClose />} style={{width: 32, height: 32, margin: '0 5px 0 5px'}}/>

                            {/* <SubmitButton label={<MdDone />}/> */}

                        </form>
                    </Row>
                }
        </Row>
    )
}


export const ExerciseSection = ({disabled, partList, selectedItem, setSelectedItem, handlePushToList, newExercise, setNewExercise}) => {
    const [selectedOption, setSelectedOption] = useState();
    const [openDropdown, setOpenDropdown] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState();
    const [openUnitDropdown, setOpenUnitDropdown] = useState(false);
    const [options, setOptions] = useState([
        { value: 'rep', label: 'Repetitions' },
        { value: 'sek', label: 'Seconds' },
        { value: 'min', label: 'Minutes' },
        { value: 'h', label: 'Hours' },
    ]);
    const [unit, setUnit] = useState([
        {value: 'kg', label: 'Kilo'},
        {value: 'lb', label: 'Pounds'},
        {value: null, label: 'No unit'}
    ]);

    const handleOnChange = (value, name) => {
        const sanitizedValue = value.replace(new RegExp('[/]', 'g'), '');
        setNewExercise({...newExercise, [name]: sanitizedValue})
    }

    const handleChange = async (e) => {
            const value = e.target.value;
            const name = e.target.name;

            if(name === 'options'){
                setNewExercise({...newExercise, options: e.target.value})
                // setSelectedOption(value);
                // setOpenDropdown(false);
            }else if(name === 'unit'){
                setNewExercise({...newExercise, unit: e.target.value})

                // setSelectedUnit(value);
                // setOpenUnitDropdown(false);
            }
      };

      const handleUnitChange = async (e) => {
        const value = e.target.value;
        setSelectedUnit(value);
        setOpenUnitDropdown(false);
      };

    const dropdown = (options, name) => {
        return(
            <select name={name} style={{background: 'transparent', border: 'none', height: '3em'}} value={selectedOption} onChange={handleChange} onClick={() => setOpenDropdown(!openDropdown)}>
                {options.map((option, index) => (
                  <option  key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
        )
    }

    return (
        <>
        <WorkoutInputContainer>
            <FilteredList data={partList} selectedItem={selectedItem} setSelectedItem={setSelectedItem}/>
        </WorkoutInputContainer>
        <form onSubmit={handlePushToList} style={{display:'flex', justifyContent:'center',alignItems:'center', height:'100%'}}>
        <WorkoutInputContainer>
         <WorkoutInputContent>
            <LABEL>Nimi:</LABEL>
            <StyledCustomInput
                name="nameOfExercise"
                type="text"
                placeholder="Squat..."
                value={newExercise?.nameOfExercise}
                onChange={(e) => handleOnChange(e.target.value, e.target.name)}
            />
            </WorkoutInputContent>
            <div className="amounts_div">
                <div className="amount_inside_div rounds_div">
                    <WorkoutInputContent>
                        <LABEL>Rounds:</LABEL>
                        <StyledCustomInput
                            name="times"
                            type="number"
                            placeholder="rounds..."
                            value={newExercise?.times}
                            onChange={(e) => setNewExercise({...newExercise, times: e.target.value})}
                        />
                    </WorkoutInputContent>
                </div>
                <div className="amount_inside_div repetition_div">
                    <WorkoutInputContent>
                        <LABEL>Amount:</LABEL>
                        <StyledCustomInput
                            name="amount"
                            type="number"
                            placeholder="amount..."
                            value={newExercise?.amount}
                            onChange={(e) => setNewExercise({...newExercise, amount: e.target.value})}
                        />
                    </WorkoutInputContent>
                    <WorkoutInputContent width={'5em'} margin={'0 10px 0.5em 10px'} >
                        {dropdown(options, 'options')}
                    </WorkoutInputContent>
                </div>
            </div>
            <WorkoutInputContent direction={'row'} align={'flex-end'}>
            <WorkoutInputContent>
            <LABEL>Weight:</LABEL>
            <StyledCustomInput
                name="weight"
                type="number"
                placeholder="weight..."
                value={newExercise?.weight}
                onChange={(e) => setNewExercise({...newExercise, weight: e.target.value})}
            />
            </WorkoutInputContent>
            <WorkoutInputContent width={'5em'} margin={'0 10px 1em 10px'} >
                {dropdown(unit, 'unit')}
            </WorkoutInputContent>
            </WorkoutInputContent>
            <WorkoutInputContent direction={'row'}>
                <WorkoutInputContent>
                    <LABEL>Description:</LABEL>
                    <StyledTextArea
                        name="description"
                        type="text"
                        placeholder="description..."
                        value={newExercise?.description}
                        onChange={(e) => setNewExercise({...newExercise, description: e.target.value})}
                    />
                </WorkoutInputContent>
                <WorkoutInputContent align={'flex-end'}>
                    <Dropzone 
                        placeholder={'Video or Image'}
                        content={newExercise} 
                        setContent={setNewExercise}
                        accept={{
                            'image/*': ['.jpeg', '.jpg', '.png', '.gif']
                            // 'video/*': ['.mp4', '.mov', '.avi']
                        }}
                    />
                </WorkoutInputContent>
            </WorkoutInputContent>
            <SubmitButton label={'Add'} disabled={disabled}/>
        </WorkoutInputContainer>
        </form>
        </>
    )
}

const FinalSection = ({exerciseList, setExerciseList, disabled}) => {
    const navigate = useNavigate();
    const {personalData, setPersonalData} = useData();
    const [value, setValue] = useState('')
    
    const navTo = () => {
        navigate(`/workout/my`);
    }

    const createObj = (name, objList) =>{
        const newObj = {
            [name]: {}
        }

        objList.forEach((obj) => {
            const key = Object.keys(obj)[0];
            newObj[name][key] = obj[key]; // Luo uuden objektin, joka kopioidaan vanhasta objektista
        });

        return newObj;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataObj = createObj(value, exerciseList);
        const formData = new FormData();
        formData.append('data', JSON.stringify(dataObj)); // Lisätään muu data

        // Lisää kaikki kuvat formData:an
        exerciseList.forEach((values, index) => {
            Object.values(values)[0].map((exercise, index) => {
                if (exercise.file) {
                    formData.append(`media_${index}`,exercise.file);
                  }
            })
          });
        
        await InsertNewWorkoutToDb(formData, navTo, setPersonalData, personalData)
    }
    
    return(
        <form onSubmit={handleSubmit} style={{display:'flex', justifyContent:'center', alignItems: 'flex-start'}}>
        <WorkoutInputContainer>
            <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                <LABEL>Name of workout</LABEL>
                    <StyledWorkoutInput
                        type="text"
                        placeholder="name..."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
            </div>

                <div style={{height:'60vh', width: '100%'}}>
                    <ScrollView items={exerciseList} renderItem={(item) => <PreviewRow exercise={item} workout={exerciseList} setExerciseList={setExerciseList}/>} />
                 </div>
                <SubmitButton disabled={value.length > 3 ? disabled : true} label={'Done'}/>
        </WorkoutInputContainer>
        </form>
    )
}

const NewWorkout = () => {
    const [partList, setPartList] = useState([
        {value:'', label:'Add name'},
        {value:'legs', label:'Legs'},
        {value:'full body', label:'Full body'},
        {value:'chest', label:'Chest'}
    ])
    const [selectedItem, setSelectedItem] = useState(partList[0].value);
    const [newExercise, setNewExercise] = useState({nameOfExercise:"",amount:"",times:"",weight:"",description:"", unit:"kg", options: "rep", file:null});
    const [exerciseList, setExerciseList] = useState([]);
    const [media, setMedia] = useState([]);
    const [disabled, setDisabled] = useState({add: true, done: true, previous: true, next: true});
    const [isFinalVisible, setIsFinalVisible] = useState(false);
    // {jalat: [{nameOfExercise:"Kyykky",amount:"10",times:"3",weight:"60",description:"dexc"}]}


    useEffect(() => {
        setDisabled({...disabled, add: !(TextMinLenCount(newExercise.nameOfExercise, 4) && TextMinLenCount(selectedItem, 2))})
    },[newExercise.nameOfExercise, selectedItem])

    
    useEffect(() => {
        if(exerciseList.length > 0){
            if(!isFinalVisible){
                setDisabled({...disabled, next: false, done: false})
            }
            // setDisabled({...disabled, done: false})
        }else{
            setDisabled({...disabled, next: true, done: true})
        }
        
    },[exerciseList.length])

    const clearStates = () => {
        setNewExercise({nameOfExercise:"",amount:"",times:"",weight:"",description:"", file: null})
    }

    const findObj = (list, key) => {
        // Boolean(list.find((name) => Object.keys(name)[0] === key))
        return list.find((name) => Object.keys(name)[0] === key)
    }

    const createNewObj = (objName, value) => {
        const newObj = {
            [objName]:[
                value
            ]
        }
        return newObj;
    }

    const handlePushToList = (e) => {
        e.preventDefault();
        if(findObj(exerciseList, selectedItem)){
            const obj = Object.values(findObj(exerciseList, selectedItem))[0]
            obj.push(newExercise)
        }else{
            exerciseList.push(createNewObj(selectedItem, newExercise));
        }

        clearStates();
    }

    const handleNext = () => {
            setIsFinalVisible(!isFinalVisible);
            setDisabled({...disabled, previous: !disabled.previous, next: !disabled.next})
    }

    const handlePrevious = () => {
        setIsFinalVisible(!isFinalVisible);
        setDisabled({...disabled, previous: !disabled.previous, next: false})
    }

    return(
        <Container direction={'column'}>
            {!isFinalVisible ?
            <ExerciseSection 
                disabled={disabled.add} 
                partList={partList} 
                selectedItem={selectedItem} 
                setSelectedItem={setSelectedItem} 
                handlePushToList={handlePushToList} 
                newExercise={newExercise} 
                setNewExercise={setNewExercise}
                media={media}
                setMedia={setMedia}/>
            :
            <FinalSection 
                exerciseList={exerciseList} 
                setExerciseList={setExerciseList} 
                disabled={disabled.done}
                media={media}
                setMedia={setMedia}/>
            }
            <ButtonContainer>
                <CustomButton disabled={disabled.previous} label={'<'} onClick={() => handlePrevious()} button={StyledSubmitButton}/>
                <CustomButton disabled={disabled.next} label={'>'} onClick={() => handleNext()} button={StyledSubmitButton}/>
            </ButtonContainer>
        </Container>
    )
}

export default NewWorkout;