import styled from 'styled-components';


export const ExerciseDetailsInput = styled.input`
padding:0; 
font-size: 17; 
text-align: center;
color: ${({theme}) => theme.colors.text}; 
text-decoration-line: none;
background: none;
width: ${(props) => props.width}px;
max-width: 3em;
height: 2em;
border: none;
`;

export const StyledSearchInput = styled.input`
padding:0; 
font-size: 17; 
text-align: center;
color: ${({theme}) => theme.colors.text}; 
text-decoration-line: none;
background: none;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
height: 25px;
`

export const StyledWorkoutInput = styled.input`
    padding:4px; 
    font-size: 17; 
    text-align: start;
    color: ${({theme}) => theme.colors.text}; 
    text-decoration-line: none;
    background: none;
    border-radius: 5px;
    height: ${({height}) => height || '25px'};
    width: ${({width}) => width && (width)}
`;

export const StyledTextArea = styled.textarea`
    padding:4px; 
    font-size: 17; 
    color: ${({theme}) => theme.colors.text}; 
    text-decoration-line: none;
    border-radius: 5px;
    // border-color: ${({theme}) => theme.colors.body};
    height:12vh;
    width: ${({width}) => width && (width)};
    max-height: 30vh;
    max-width: 100%;
    background: ${({theme}) => theme.colors.body};
`

export const LABEL = styled.label`
    color:${({theme}) => theme.colors.text};
    margin-right: 8px;

`;

export const StyledCustomInput = styled.input`
    margin: ${({margin}) => margin ? margin : '0 0 10px 0'};
    padding:0;
    // margin-bottom: 5px;
    // width: 100%;
    border-radius: 5px;
    // border-color: ${({theme}) => theme.colors.body};
    // background: ${({theme}) => theme.colors.body};
    background: transparent;
    color: ${({theme, disabled}) => disabled ? theme.colors.disabledText : theme.colors.text}; 
    text-decoration-line: ${({text_decoration}) => text_decoration || 'none'};
    width: ${({width}) => width ? width+'px' : '100%'};
    // max-width: 3em;
    height: 3em;
    cursor: text;
    border: ${({border}) => border && (border)};
    text-align: ${({text_align}) => text_align && (text_align)};

    @media screen and (min-width: 481px) and (min-height: 500px) {

    }
`