// encryption.js
import CryptoJS from 'crypto-js';

const { REACT_APP_SECRET_KEY: SECRET_KEY, REACT_APP_SECRET_IV: SECRET_IV, REACT_APP_ENCRYPTION_METHOD: ENCRYPTION_METHOD } = process.env;

if (!SECRET_KEY || !SECRET_IV || !ENCRYPTION_METHOD) {
  throw new Error('SECRET_KEY, SECRET_IV, and ENCRYPTION_METHOD are required');
}

// Generate secret hash with CryptoJS to use for encryption
const key = CryptoJS.enc.Hex.parse(CryptoJS.SHA512(SECRET_KEY).toString().substring(0, 32));
const iv = CryptoJS.enc.Hex.parse(CryptoJS.SHA512(SECRET_IV).toString().substring(0, 16));

// Encrypt data
function encryptData(data) {
  const cipher = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(data)), key, { iv });
  return cipher.toString(); // Encrypts data and converts to base64
}

// Decrypt data
function decryptData(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, key, { iv });
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData; // Decrypts data and converts to utf8
}

export {
  encryptData,
  decryptData
};
