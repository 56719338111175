import styled from 'styled-components';

export const ForgotPwdImage = styled.img`
  max-height: 30em;
  padding: 0.2em 0.2em 0 0.2em;

  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export const LogoWrapper = styled.span`
  background-image: url(${(props) => props.src});
  background-size: contain; /* Säädä kuvan kokoa */
  background-repeat: no-repeat; /* Estää kuvaa toistumasta */
  background-position: left center;
  width: 60%;
  height: 100%;
  position: absolute; /* Aseta absoluuttisesti suhteessa parenttiin */
  top: 0; /* Sijoita ylälaidalle */
  left: 0; /* Sijoita vasemmalle laitalle */
  z-index: -1; /* Varmista, että kuva on muiden elementtien alla */
  margin: auto auto auto 5em;

//   @media screen and (max-width: 650px) {
//     display: none;
//   }
`;