import { SaveSubscription } from "./ApiRequests";
import config from "../config.json";

const PUBLIC_VAPID_KEY = config.VAPID_PUBLIC_KEY;

  // subscribeUserToPush -funktion määrittely
  export const subscribeUserToPush = () => {
    navigator.serviceWorker.ready.then(registration => {
      registration.pushManager.subscribe({
        userVisibleOnly: true,  // Ilmoitukset näkyvät vain käyttäjälle
        applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY)
      }).then(subscription => {
        return SaveSubscription(subscription);
      }).then(response => {
        if (response.ok) {
          console.info('Push-tilaus tallennettu palvelimelle.');
        } else {
          console.error('Virhe tallennettaessa push-tilausta.');
        }
      }).catch(error => {
        console.error('Push-tilauksen virhe:', error);
      });
    });
}
  // Muunna VAPID-avaimen base64-muoto Uint8Array:ksi
  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  