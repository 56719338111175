import styled from 'styled-components';

export const StyledHeader = styled.div`
  position:fixed;
  top: 0;
  // left: 0.5em;
  width: 100%;
  background: ${({theme}) => theme.colors.body};
  display: grid;
  grid-template-columns: ${(props) => props.columns || '1fr 1fr 1fr'};
  column-gap:1px;  
  // align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
  color: ${({theme}) => theme.colors.text};
  z-index: 1000;
`;

export const HeaderContent = styled.div`
    display: flex;
    justify-content: ${(props) => props.justify || 'center'};
    align-items: center;
`

export const ViewHeader = styled.h3`
  position: fixed;
  top: 60px;
`