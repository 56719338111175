import React, {createContext, useContext, useReducer, useState, useEffect} from 'react';
import {jwtDecode} from 'jwt-decode';
import { GetCookieData, ClearLoginCookie, SetToLocalStorage, getFromLocalstorage } from './Utils';
import { fetchPersonalData, refreshAccessToken } from './ApiRequests';
import { encryptData, decryptData } from './encryption';
import { useNavigate } from 'react-router-dom';

const ws_host = process.env.REACT_APP_WS_API_KEY;

let initialState = {
    isAuthenticated: false,
    token: null,
    user: null
  };

 let initialDataState = {
  data: null
 }

 let initialPublicDataState = {

 }

 let initialNotifications = {
    notifications: null
 }
  
  // Reducer-funktio käsittelemään tilan muutoksia
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN':
        return {
          ...state,
          isAuthenticated: true,
          token: action.payload,
          user: action.user
        };
      case 'LOGOUT':
        return {
          ...state,
          isAuthenticated: false,
          token: null,
          user: null,
        };
      default:
        return state;
    }
  };

   const dataReducer = (state, action) => {
     switch (action.type) {
       case 'LOAD':
         return {
           ...state,
           data: action.payload
         };
        case 'NOTIFICATION':
          return {
            ...state,
            notifications: action.payload
          }
       default:
         return state;
     }
   };

export const AuthContext = createContext();
export const DataContext = createContext();
export const TimerContext = createContext();
export const WebSocketContext = createContext(null);


  const AuthProvider = ({ children }) => {
    initialState = GetCookieData() ? GetCookieData() : initialState;
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
      if(GetCookieData() === null){
        dispatch({type: 'LOGOUT'})
      }
    },[GetCookieData()])
    
    return (
      <AuthContext.Provider value={{ state, dispatch }}>
        {children}
      </AuthContext.Provider>
    );
  };

  const DataProvider = ({ children }) => {
    const navigate = useNavigate();
    const {state, dispatch} = useAuth();
    let initialNotifications = null;
    const getNotification = localStorage.getItem('notifications');

    if(getNotification){
      const decryptNotification = decryptData(getNotification);
      initialNotifications = JSON.parse(decryptNotification);
    }

    const [personalData, setPersonalData] = useReducer(dataReducer, initialDataState);
    const [publicData, setPublicData] = useReducer(dataReducer, initialPublicDataState);
    const [notifications, setNotifications] = useReducer(dataReducer,JSON.parse(initialNotifications));

     useEffect(() => {
      if (notifications) {
        const encryptNotification = encryptData(JSON.stringify(notifications)); 
        localStorage.setItem('notifications', encryptNotification);
      }
    }, [notifications]);

    useEffect(() => {
      if(state?.isAuthenticated){
        fetchPersonalData(setPersonalData);
      }
    }, []);
    
     return (
       <DataContext.Provider value={{ publicData, personalData, notifications, setPublicData, setPersonalData, setNotifications }}>
         {children}
       </DataContext.Provider>
     );
  };

  const TimerProvider = ({ children }) => {
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    
    return (
      <TimerContext.Provider value={{ isTimerRunning, setIsTimerRunning }}>
        {children}
      </TimerContext.Provider>
    );
  };

  const WebSocketProvider = ({ children }) => {
    const [ws, setWs] = useState(null);
    const {state} = useAuth();


    const createWebSocketConnection = async () => {
      const wsConnection = new WebSocket(ws_host);
  
      // Luo WebSocket-yhteys
      wsConnection.onopen = async () => {
        console.info("WebSocket connection opened");

        let token = state?.token;
  
        // Tarkista, onko token olemassa ja onko se voimassa
        if (token) {
          const decoded = jwtDecode(token);
  
          if (decoded && decoded.exp * 1000 <= Date.now()) {
  
            // Token on vanhentunut, yritetään uusia se
            await refreshAccessToken();
            const getCookie = GetCookieData();
  
            token = getCookie?.token; // Hae uusi token tallennuksesta
  
          }
        }
  
        // Lähetä käyttäjätunnus, jos token on saatavilla
        if (token) {
          wsConnection.send(JSON.stringify({ type: 'SET_USER_ID', token }));
        }
        
        // Lähetä REQUEST_PUBLIC_DATA -viesti aina, riippumatta tokenista
        wsConnection.send(JSON.stringify({ type: 'REQUEST_PUBLIC_DATA' }));
  
        // Lähetä REQUEST_NOTIFICATION -viesti vain, jos token on saatavilla
        if (token) {
          wsConnection.send(JSON.stringify({ type: 'REQUEST_NOTIFICATION', token }));
        }
      };
  
      wsConnection.onclose = () => {
        console.info("WebSocket connection closed");
      };
  
      setWs(wsConnection);
    };
  
    useEffect(() => {
      createWebSocketConnection();
  
      return () => {
        if (ws) {
          ws.close();
        }
      };
    }, [state.token]);
  
    return (
      <WebSocketContext.Provider value={ws}>
        {children}
      </WebSocketContext.Provider>
    );
  }

  const useAuth = () => {
    const context = useContext(AuthContext);
    
    if (!context) {
      throw new Error('useAuth hook voidaan käyttää vain AuthProviderin sisällä');
    }
    return context;
  };

  const useData = () => {
     const context = useContext(DataContext);

     if (!context) {
       throw new Error('useData hook voidaan käyttää vain DataProviderin sisällä');
     }
     return context;
  };

  const useTimer = () => {
    const context = useContext(TimerContext);
    if(!context){
      throw new Error('useTimer hook voidaan käyttää vain TimerProviderin sisällä')
    }
    return context;
  }

  const useWebSocket = () => {
    return useContext(WebSocketContext);
  };


  export {AuthProvider, useAuth, DataProvider, WebSocketProvider, useData, TimerProvider, useTimer, useWebSocket}