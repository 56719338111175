import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../Utils/UseContext.jsx';
import { CustomButton } from '../components/Buttons.jsx';
import { HandleLogin } from '../Utils/ApiRequests.jsx';

//icon
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

//style
import { StyledSubmitButton } from '../components/style/Buttons.styled.jsx';
import { AuthContainer, FormContainer, AuthContent } from '../components/style/Container.styled.jsx';
import { StyledCustomInput } from '../components/style/CustomInput.styled.jsx';
import '../components/style/Styles.css'

const ErrorMsg = (msg) => {
  const wrongCredsText = msg;
  //"Salasanan tai tunnus väärin.";
  return(
      <div>
          <p>{wrongCredsText}</p>
      </div>
  )
}

const Login = () => {
    const navigate = useNavigate();
    // const {setToken} = AuthContext();
    const { state, dispatch } = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isWrong, setIsWrong] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [isVerified, setIsVerified] = useState(true);

    return(
        // <div className='authContainer'>
        <AuthContainer>
          <AuthContent>
          <FormContainer>
          <h3>Login</h3>
            <StyledCustomInput
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              onFocus={() => setIsWrong(false)}

            />
            <div className='password-container'>
              <StyledCustomInput
                id="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                onFocus={() => setIsWrong(false)}
                type={showPassword ? 'text' : 'password'}
              />
              <span className='show-password' onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? 
                              <IoMdEyeOff size={25} color={'black'}/>
                              :
                              <IoMdEye size={25} color={'black'}/>
                          }
              </span>
            </div>
            <CustomButton button={StyledSubmitButton} label={"Login"} onClick={() => HandleLogin(email, password, navigate, dispatch, setIsWrong, setIsVerified)} style={{color: 'white'}}/>

          </FormContainer>

          {/* </div> */}
          {!isVerified && (ErrorMsg("Check your email and Verify your account."))}
          {isWrong && (ErrorMsg("Salasanan tai tunnus väärin."))}
          <p style={{cursor: 'pointer'}} onClick={() => navigate('/forgot/password')}>Forgot password?</p>
          <p>You do not have an account? <span style={{cursor: 'pointer'}} onClick={() => navigate('/registeration')}>Register</span></p>
      </AuthContent>
      </AuthContainer>
    )
}

export default Login;