import React, { useEffect, useState } from "react";
// API
import { GetCompletedWorkout } from "../../Utils/ApiRequests";
// style
import { Content20Round,Container} from "../../components/style/Container.styled";
import { Row } from "../../components/style/Row.styled";
//Button
//components
import { TrainingGraph, WeightGraph } from "./Charts";
//Utils
import { FilterEventsByDateRange, GetWeekRange, GetPreviousWeekRange} from "../../Utils/Utils";


const countActivities = (list) => {
    const counts = {};
 
    list.forEach(item => {

      if (!counts[item.part_of_workout_id]) {
        counts[item.part_of_workout_id] = {};
      }

      if (counts[item.part_of_workout_id][item.part_of_workout_name]) {
        counts[item.part_of_workout_id][item.part_of_workout_name]++;
      } else {
        counts[item.part_of_workout_id][item.part_of_workout_name] = 1;
      }
    });

    return counts;
};

const countActivitiesDuration = (list) => {
  
    // Lasketaan tapahtumien kokonaiskesto sekunteina
    const totalDurationInMillseconds = list.reduce((total, item) => total + item.duration, 0);

    // Muunnetaan sekunnit tunneiksi ja minuuteiksi
    const hours = Math.floor(totalDurationInMillseconds / (3600 * 1000));
    const minutes = Math.floor((totalDurationInMillseconds % (3600 * 1000)) / (60 * 1000));
    const seconds = Math.floor((totalDurationInMillseconds % (60 * 1000)) / 1000);
  
    return { hours, minutes, seconds };
};

const WeeklyActivieties = ({lastWeek, thisWeek}) => {
    const difference = thisWeek - lastWeek;

    const LessThisWeek = `You have done ${thisWeek} activities this week which is ${Math.abs(difference)} less than last week`;
    const MoreThisWeek = `You have done ${thisWeek} activities this week which is ${difference} more than last week`;

    return difference > 0 ? MoreThisWeek : LessThisWeek
}

const Activities = ({completed}) => {
    const counts = countActivities(completed)

    return(
        <div style={{display: 'flex', flexDirection: 'row', maxWidth: '70%', overflow: 'auto'}}>
        {Object.keys(counts).map((id, index) => {
          const name = Object.keys(counts[id])[0];
          const count = counts[id][name];
          
          return(
            <div key={index} style={{margin: '10px 5px 10px 0px', borderRight: '1px solid white', padding: '0px 5px 0px 0px' }}>
              <div>{name}</div>
              <div>{count}</div>
            </div>
          )
        })}
      </div>
    )
}


const Analytics = () => {
  const [trainingData, setTrainingData] = useState([]);
  
  useEffect(() => {
    getTrainingData();
  },[])



  const getTrainingData = async () => {
    const data = await GetCompletedWorkout()
    setTrainingData(data.completed.completed);
}

const currentWeekEvents = FilterEventsByDateRange(trainingData, GetWeekRange());
const previousWeekEvents = FilterEventsByDateRange(trainingData, GetPreviousWeekRange());
const hours = countActivitiesDuration(currentWeekEvents).hours;
const minutes = countActivitiesDuration(currentWeekEvents).minutes;

    return(
        <Container direction={'column'} justify={'flex-start'}>
            <h3>
                Analytics
            </h3>
          {/* <ScrollAuto> */}
            <Content20Round>
              <h4 style={{textAlign:'center'}}>Total</h4>
              <Row>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p>Activities</p>
                    <p>{trainingData.length}</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p>Time</p>
                    <p>{hours}h {minutes}min</p>
                </div>
              </Row>
            </Content20Round>
            <Content20Round>
                <h4>Weekly activities</h4>
                <WeeklyActivieties lastWeek={previousWeekEvents.length} thisWeek={currentWeekEvents.length}/>
                <Row padding={'0px'}>
                    <Activities completed={currentWeekEvents}/>
                </Row>
            </Content20Round>
            <Content20Round>
                <TrainingGraph data={trainingData} />
            </Content20Round>
            <Content20Round>
                <Row padding={'0px'}>
                    <WeightGraph data={trainingData}/>
                </Row>
            </Content20Round>
            {/* </ScrollAuto> */}
        </Container>
    )
}

export default Analytics;