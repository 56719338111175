import React from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Term_of_service from './TermsOfService.json'

//style
import { PrivacyPolicyContainer } from "../components/style/Container.styled";

const TermOfService = ({language}) => {
    const navigate = useNavigate();
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = Term_of_service?.supported[lang] ? Term_of_service?.supported[lang] : Term_of_service?.supported['en-EN']
    
    return(
        <>
        <PrivacyPolicyContainer>
            <h2>{Term_of_service.title[lang]}</h2>
            <div>
                <h3>{Term_of_service.section1.title[lang]}</h3>
                {Term_of_service.section1.content[lang]}
            </div>
            <div>
                <h3>{Term_of_service.section2.title[lang]}</h3>
                {Term_of_service.section2.content[lang]}
            </div>
            <div>
                <h3>{Term_of_service.section3.title[lang]}</h3>
                {Term_of_service.section3.content[lang]}
            </div>
            <div>
                <h3>{Term_of_service.section4.title[lang]}</h3>
                {Term_of_service.section4.content[lang]}
            </div>
            <div>
                <h3>{Term_of_service.section5.title[lang]}</h3>
                {Term_of_service.section5.content[lang].map(item => { return <li style={{listStyle: 'none', marginTop: '2px'}}>{item}</li>})}
            </div>
            <div>
                <h3>{Term_of_service.section6.title[lang]}</h3>
                {Term_of_service.section6.content[lang]}
            </div>
            <div>
            <h3>{Term_of_service.section7.title[lang]}</h3>
            {Term_of_service.section7.content[lang]}
            </div>
            <div>
            <h3>{Term_of_service.section8.title[lang]}</h3>
            {Term_of_service.section8.content[lang]}
            </div>
            <div>
            <h3>{Term_of_service.section9.title[lang]}</h3>
            {Term_of_service.section9.content[lang]}

            </div>
            <div>
            <h3>{Term_of_service.section10.title[lang]}</h3>
            {Term_of_service.section10.content[lang]}

            </div>
            <div>
            <h3>{Term_of_service.section11.title[lang]}</h3>
            {Term_of_service.section11.content[lang]}
            </div>
            <div>
            <h3>{Term_of_service.section12.title[lang]}</h3>
            {Term_of_service.section12.content[lang]}
            </div>
            <div>
            <h3>{Term_of_service.section13.title[lang]}</h3>
            {Term_of_service.section13.content[lang]}<span onClick={() => navigate('/contact')} style={{cursor: 'pointer'}}>{Term_of_service.section13.link[lang]}</span>
            </div>
        </PrivacyPolicyContainer>
        </>
    )
}

export default TermOfService;