import React, {useState, useEffect} from "react";
import { Navigate, useLocation, useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
//Utils
import { useAuth, useData, useWebSocket } from "../../../Utils/UseContext.jsx";
import { Loading, DateNow, DateToString, AddTokenToHeaders, returnCurrency} from "../../../Utils/Utils.jsx";
//API
import { ConfirmAccessRight } from "../../../Utils/ApiRequests.jsx";
//COmponents
import { Modal, ScrollView, ExpiryDate, CustomPopUp} from "../../../components/Components.jsx";
import { WorkoutMedia } from "../WorkoutMedia.jsx";
//icon
import { MdOutlineReadMore } from "react-icons/md";
//Style
import { ButtonContainer, Container100, PublishDetailsContainer, DescriptionContainer } from "../../../components/style/Container.styled.jsx";
import { Column, Row } from "../../../components/style/Row.styled.jsx";
import { StyledSubmitButton } from "../../../components/style/Buttons.styled.jsx";
import { WorkoutRow, WorkoutContent, WorkoutContainer, ExerciseContainer, WorkoutBox} from '../StyledComponents.jsx'
import { NowrapH4 } from "../../../components/style/paragraph.styled.jsx";



const PublicExerciseRow = ({theme, nameofcreator, partnames, title, price, expiredIn, media, currency, onClick}) => {
  const testmode = true;
  
     return(
       <WorkoutRow columns={'1fr 8fr'} onClick={onClick} >
        <WorkoutBox>
            <WorkoutMedia src={media} type="image"/>
        </WorkoutBox>
        {/* <WorkoutBox column={'2 / 2'} row={'1 / 3'}> */}
          <NowrapH4>{title}</NowrapH4>
        {/* </WorkoutBox> */}
         <WorkoutBox column={'2 / 2'} row={'2 / 3'}> 
              <p style={{fontSize: 10}}>{partnames?.length} part training program</p>
              <Row padding={'0px 30px 0px 0px'}>
                <p>{'Expires in'} {expiredIn} {'days'}</p>
                <div style={{padding: '2px 10px 2px 10px', borderRadius: 50, border: `solid 1px ${theme.colors.border}`}}>
                  <p style={{fontSize:25}}>{price}{returnCurrency(currency)}</p>
                </div>
              </Row>
         </WorkoutBox>
        <WorkoutBox column={'1 / 3'} row={'3 / 3'} font={10}>
          <p>Created by {nameofcreator}</p>
       </WorkoutBox>
     </WorkoutRow>
     )
   }
  
export const PublicExerciseSection = ({data, theme}) =>{
      const navigate = useNavigate();
      const {state} = useAuth();
      const location = useLocation();
      const {value} = location.state || {};
      const [isOpen, setIsOpen] = useState(false);
      const [previewData, setPreviewData] = useState(null);

      const publicExercise = Object.values(data).map(item => {
        const workout_name = item?.workout_name;
        const username = item?.username;
        const workout_id = item?.workout_id;
        const description = item?.description;
        const expired_time = item?.expired_time;
        const price = item?.price;
        const media = item?.media_url;
        const display_parts = item?.display_parts;
        const part_names = item?.part_names;
        const currency = item?.currency;
  
        return (
          <PublicExerciseRow key={Math.random()} 
            onClick={() => navigate(`/workout/public/${workout_id}`)} 
            theme={theme} id = {workout_id} nameofcreator = {username} title = {workout_name} partnames={part_names} price = {price} expiredIn = {expired_time} description = {description} media={media} currency={currency} display_parts={display_parts}/>
        )
      })
  
      return (
        <ExerciseContainer justify={'start'} >
          {publicExercise}
        </ExerciseContainer>
        );
   }