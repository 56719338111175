import React, {useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HeaderContent, StyledHeader } from "./style/Header.styled.jsx";
import { useTimer, useAuth } from "../Utils/UseContext.jsx";
import Logo from './style/images/Logo.png'
import { MdArrowBackIos } from "react-icons/md";
import { LoginIcon, LogoutIcon } from "./AuthIcon.jsx"; 
import { ProfileIcon } from "./Nav.jsx";
import { CheckServer, GetPublicData, HandleLogout } from "../Utils/ApiRequests.jsx";
//icons
import { MdOutlineCircleNotifications, MdOutlineNotificationsActive } from "react-icons/md";
import { useData, useWebSocket } from "../Utils/UseContext.jsx"; 



const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {state, dispatch} = useAuth();
    const {isTimerRunning} = useTimer();
    const [isup, setisup] = useState(false);
    const {notifications, setNotifications} = useData();
    const ws = useWebSocket();

    //Laitetaan tila joka muuttuessa näyttää määrän

    useEffect(() => {
        if(state?.isAuthenticated && ws){
            GetPublicData({setNotifications, ws});
        }
    },[ws])

    const navTo = () => {
        if(!isTimerRunning){
            navigate('/');
        }
    }

    const back = () => {
        navigate(-1);
    }

    const isUp = async () => {
        return await CheckServer();
    }

    isUp().then(serverStatus => {
        setisup(serverStatus)
    });

    const handleLogout = async () => {
        await HandleLogout(navigate, dispatch)
    }

    return(
        <StyledHeader>
            <HeaderContent justify={'flex-start'}>
                <MdArrowBackIos onClick={() => back()} size={20}/>
                <div style={{width: '5px', height: '5px', background: !isup ? 'red' :'transparent', borderRadius: '50%'}}></div>
            </HeaderContent>
            <HeaderContent>
                <img onClick={() => navTo()} style={{height:'2.5em', cursor:'pointer'}}src={Logo} alt="Logo" />
            </HeaderContent>
            <HeaderContent justify={'flex-end'}>
                {(state?.isAuthenticated && location.pathname !== '/notifications') && ((notifications?.notifications?.length <= 0 || !notifications?.notifications) ? <MdOutlineCircleNotifications size={20} /> : <MdOutlineNotificationsActive onClick={() => navigate('/notifications')} size={20}/>)}
                {location.pathname === '/profile'? <LogoutIcon onClick={handleLogout} /> : <ProfileIcon />}
                <LoginIcon />
            </HeaderContent>
        </StyledHeader>
    )
}

export default Header;