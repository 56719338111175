import React, {useEffect, useState} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fi'; // Import Finnish locale
//API
import { InsertWorkoutEvent } from "../../Utils/ApiRequests";
//Data
import { useData } from "../../Utils/UseContext";
//Utils
import { ScrollView } from "../../components/Components";
import { isObjectEmpty } from "../../Utils/Utils";
//Style
import { CustomDatepicker, CustomDateTimePicker } from "../../components/style/Date.styled";
import { Container, CalendarPlanerContainer } from "../../components/style/Container.styled";
import { StyledSubmitButton } from "../../components/style/Buttons.styled";
import "../../components/style/datetime-style.css"
import { Row } from "../../components/style/Row.styled";
//icon
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
//Buttons
import { CustomButton } from "../../components/Buttons";
import { InsertCalenderExercise } from "../../Utils/ApiRequests";
import { Loading } from "../../Utils/Utils";

function findKeyByObjectName(data, targetName, targetKey) {

    for (const item of data) {
        const key = Object.keys(item)[0];
        const values = item[key];
     
        if (values.some(obj => obj[targetKey] === targetName)) {
            return {key: key, value: values};
        }
    }
    return null;
}

const Datepicker = (props) => {
    moment.locale('en'); // this need to retrieve from logged user (default 'en')
    return(
        <div style={{display: 'flex', flexDirection: 'row', margin: '0 0 15px 5px'}}>        
            StartTime:
            <CustomDateTimePicker
                onChange={props.handleDateChange}
                value={props.date}
                dateFormat={moment.localeData().longDateFormat('L')}
                timeFormat={moment.localeData().longDateFormat('LT')}
                theme={props.theme}
            />
        </div>
    )
}

const DropdownList = ({theme, data, selectedWorkout, setSelectedWorkout}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isPartOpen, setIsPartOpen] = useState(false);
    const [newItem, setNewItem] = useState('');
    const [values, setValues] = useState([]);
    const [partOfWorkoutNames, setPartOfWorkoutNames] = useState([]);

    //List of workout title
    const workoutNames = data?.map((item) => {
        const key = Object.keys(item)[0]
        if(key !== 'accessRight'){
            return key;
        }
    } )
  
    const toggleDropdown = () => setIsOpen(!isOpen);
    const togglePartDropdown = () => setIsPartOpen(!isPartOpen);

    const setPartNames = (workoutName) => {

        const partOfWorkoutNamesList = data.flatMap(item => {
            const key = Object.keys(item)[0];
            if (key === workoutName) {
                const values = Object.values(item)[0];
                // Update values
                setValues(values)

                return item[key]
                   .flatMap(exercise => Object.values(exercise)[0])
                   .filter(exercise => exercise.part_of_workout_name)
                   .map(exercise => exercise.part_of_workout_name)
            }else{
                return [];
            }
        });
        // asetetaan part nimet listaan
        setPartOfWorkoutNames(partOfWorkoutNamesList);

    }

    useEffect(() => {
        const initialWorkout = workoutNames[0];

         setSelectedWorkout(prev => ({
           ...prev,
           title: initialWorkout
         }));

        setPartNames(initialWorkout);

      }, []);

       useEffect(() => {
        if (partOfWorkoutNames.length > 0) {
            const keyValuePair = findKeyByObjectName(values, partOfWorkoutNames[0], 'part_of_workout_name');
            
            setSelectedWorkout(prev => ({
                ...prev,
                name_of_exercise: partOfWorkoutNames[0],
                workout_value: keyValuePair?.value,
                part_of_workout_id: keyValuePair?.key
            }));
        }
       }, [partOfWorkoutNames])

    const handleSelectWorkout = (item) => {
        setSelectedWorkout(prev => ({
            ...prev,
            title: item
            // exercise: partOfWorkoutNames[0]
          }));

        setPartNames(item);
      };

    const handleSelectPart = (item) => {
        const keyValuePair = findKeyByObjectName(values, item, 'part_of_workout_name');
       
        setSelectedWorkout(prev => ({
            ...prev,
            name_of_exercise: item,
            workout_value: keyValuePair?.value,
            part_of_workout_id: keyValuePair?.key
          }));

        setIsPartOpen(false);
    };

      const filteredWorkouts = workoutNames?.filter(item =>
        item.toLowerCase().includes(newItem.toLowerCase())
      );

       const filteredParts = partOfWorkoutNames?.filter(item =>
         item.toLowerCase().includes(newItem.toLowerCase())
       );

        const dropdown = (options, handle) => {
         return(
             <select style={{background: 'transparent', border: 'none', width: '15em', height: '5em', fontSize: '16px'}} value={selectedWorkout?.title} onChange={(e) => handle(e.target.value)}>
                 {options.map((option, index) => (
                   <option key={index} value={option}>
                     {option}
                   </option>
                 ))}
             </select>
         )
        }

    return(
        <>
        {dropdown(filteredWorkouts, handleSelectWorkout)}
        {dropdown(filteredParts, handleSelectPart)}

            {/* {!isOpen ?
            <>
                <div onClick={() => toggleDropdown()} style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', cursor: 'pointer', marginBottom: '10px', width: '15em'}}>
                    <h4>{selectedWorkout?.title}</h4>
                    <MdOutlineArrowDropDownCircle size={20} color={theme.colors.text} style={{cursor: 'pointer'}}/>
                </div>
            </>
            :
            <ul style={{listStyle: 'none', paddingLeft: 0, color:'white', height: 'fit-content',  zIndex: 2, background: 'black', padding: '10px', background: 'red'}}>
                <ScrollView items={filteredWorkouts}  renderItem={(item) => <p onClick = {() => toggleDropdown()} style={{cursor: 'pointer'}}>{item}</p>} onClick={handleSelectWorkout}/>
            </ul>
            } */}
{/* 
            {!isPartOpen ?
            <>
                <div onClick={() => togglePartDropdown()} style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', cursor: 'pointer', marginBottom: '10px', width: '15em'}}>
                    <h4>{selectedWorkout?.name_of_exercise}</h4>
                    <MdOutlineArrowDropDownCircle size={20} color={theme.colors.text} style={{cursor: 'pointer'}}/>
                </div>
            </>
            :
            <ul style={{listStyle: 'none', paddingLeft: 0, color:'white', height: 'fit-content',  zIndex: 2, background: 'black', padding: '10px'}}>
                <ScrollView items={filteredParts}  renderItem={(item) => <p onClick = {() => togglePartDropdown()} style={{cursor: 'pointer'}}>{item}</p>} onClick={handleSelectPart}/>
            </ul>
            } */}
        </>
    )
}

const Content = ({selected}) => {

    return (
        <ul style={{listStyle: 'none', margin: 0, padding: 0,width:'15em'}}>
        {selected && (
            selected.map((value, index)=> {
                if(value?.part_of_workout_name) return;
                return (
                    <li key={index}>
                        {value?.NameOfExercise}
                    </li>
                )
             }
            ))
        }
        </ul>
    )
}

const WorkoutPlaner = ({theme}) => {
    const navigate = useNavigate();
    const {personalData} = useData();
    const [selectedWorkout, setSelectedWorkout] = useState({workout_id: null, title: null, name_of_exercise: null, part_of_workout_id: null, workout_value: null, date_time:new Date()});
   
    const values = personalData?.data && (Object.values(personalData?.data));

    useEffect(() => {
        if(!personalData?.data) return;
        const workout_id = Object.entries(personalData?.data).find(([key, value]) => {
            return value.hasOwnProperty(selectedWorkout?.title);
        })?.[0]; // ?. on optional chaining, jos ei löydy, foundKey on undefined

        if (workout_id) {
            setSelectedWorkout(prev => ({
                ...prev,
                workout_id: workout_id
              }));
        }
    }, [personalData?.data, selectedWorkout?.title]);

    const navto = () => {
        navigate('/calendar', {state: {from: 'workout_event'}})
    }

    const handleDateChange = async (date) => {
        setSelectedWorkout(prev => ({
            ...prev,
            date_time: date
          }));
    }

    const handleSaveEvent = async () => {
        //SAVE DATA TO DB (start_time, workout_id, part_of_workout_id)
        InsertWorkoutEvent(selectedWorkout, navto)

        // navigate('/calendar')
    }

    return(
        <Container>
            <CalendarPlanerContainer>
                <Loading data={personalData?.data} theme={theme}>
                    { isObjectEmpty(personalData?.data) ?
                        <p onClick={() => navigate(`/workout/new`)}>Please click here and create new exercise first.</p>
                    :
                    <>
                        <div style={{}}>
                            <Datepicker handleDateChange={handleDateChange} date={selectedWorkout?.date_time} theme={theme} />
                            <DropdownList theme = {theme} data={values} selectedWorkout={selectedWorkout} setSelectedWorkout={setSelectedWorkout}/>
                            <Content selected={selectedWorkout?.workout_value}/>
                        </div>
                        <CustomButton onClick={() => handleSaveEvent()} button={StyledSubmitButton} label={'Save'} />
                    </>
                    }
                </Loading>
            </CalendarPlanerContainer>
        </Container>
    )
}

export default WorkoutPlaner;