import React, {useEffect} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//components
import PersonalExercises from "./PersonalExercises.jsx";
import { WorkoutNav } from "../../../components/Nav.jsx";
import { AddButton } from "../../../components/Buttons.jsx";
//style
import {WorkoutContainer} from '../StyledComponents.jsx'
import { StyledAnalyticsButton } from "../../../components/style/Buttons.styled.jsx";
import { CustomButton } from "../../../components/Buttons.jsx";
import { RightBottomFloatingContainer, ColumnContainer } from "../../../components/style/Container.styled.jsx";
//utils
import { useData } from "../../../Utils/UseContext.jsx";
import { HasRole} from "../../../Utils/Role.jsx";

//icons
import { TbBrandGoogleAnalytics } from "react-icons/tb";



const PersonalWorkout = ({theme}) => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const {personalData, setPersonalData} = useData();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success');

    if(success){
      toast.success("congratulations, you've got a new exercise!");
      navigate(location.pathname, { replace: true });
    }

  },[location.search])

  const navToAnalytics = async () =>{
    const isRole = await HasRole('admin');
    if(!isRole){
      toast.info("Upcoming feature!");
      return;
    }else{
      navigate('/analytics')
    }
  }

  return(
      <WorkoutContainer>
        <WorkoutNav />
        <PersonalExercises theme={theme} personalData = {personalData?.data} setPersonalData={setPersonalData}/>
        <RightBottomFloatingContainer>
          <ColumnContainer>
            <CustomButton button={StyledAnalyticsButton} onClick = {() => navToAnalytics()} label={<TbBrandGoogleAnalytics size={20}/>}/>
            <AddButton onClick={() => navigate(`/workout/new`)}/>
          </ColumnContainer>
        </RightBottomFloatingContainer>
      </WorkoutContainer>
  )
}

export default PersonalWorkout;