import React, { useState, useEffect } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
// style
import '../components/style/Styles.css'
//utils
import { DateNow } from '../Utils/Utils';

const CookieBanner = () => {

    const [preferences, setPreferences] = useState({
        necessary: true,
        statistics: false,
        marketing: false,
      });
    
      useEffect(() => {
        const acceptedCookies = JSON.parse(localStorage.getItem('acceptedCookies'));
        if (acceptedCookies) {
            setPreferences(acceptedCookies); 
          }
      }, []);

      useEffect(() => {
        localStorage.setItem('acceptedCookies', JSON.stringify(preferences));
        // Käynnistä evästeet tarvittaessa
      }, [preferences]);

      function setCookie(name, cookieData) {
        const currentDate = DateNow();
        const expirationDate = new Date(currentDate);
        expirationDate.setFullYear(currentDate.getFullYear() + 1);
      
        console.log("NAME: ", name, " DATA: ", cookieData)
        document.cookie = `${name}=${cookieData}; expires=${expirationDate.toString()}; path=/`;
        console.log("TALLENNETTU COOKIE: ", document.cookie)
      }
    
      const handleAccept = () => {
        localStorage.setItem('acceptedCookies', JSON.stringify(preferences));
        //const cookieData = `necessary=${preferences.necessary}; statistics=${preferences.statistics}; marketing=${preferences.marketing};`
        document.cookie = `NosVitaleCookies=${preferences.necessary}; path=/;`;
        //setCookie('NosVitaleCookies', cookieData);
        // Käynnistä tai estä evästeet käyttäjän valintojen perusteella
        if (preferences.statistics) {
          // Käynnistä tilastoevästeet
        }
        if (preferences.marketing) {
          // Käynnistä markkinointievästeet
        }
      };

      const handleAcceptAll = () => {
        const updatedPreferences = { necessary: preferences.necessary, statistics: true, marketing: true };
        setPreferences(updatedPreferences);
        //document.cookie = `NosVitaleCookies=necessary=${updatedPreferences.necessary}; statistics=true; marketing=true; path=/;`; 
        
        //const cookieData = `necessary=${preferences.necessary}; statistics=${preferences.statistics}; marketing=${preferences.marketing};`
        document.cookie = `NosVitaleCookies=${preferences.necessary}; path=/;`;
        //setCookie('NosVitaleCookies', cookieData);
        localStorage.setItem('acceptedCookies', JSON.stringify(preferences));
        // käynnistä evästeet
      }
    
      const handlePreferenceChange = (event) => {
        const { name, checked } = event.target;
        setPreferences((prev) => ({ ...prev, [name]: checked }));
      };

    return(
            <CookieConsent
              location="top"
              buttonText="Accept all"
              enableDeclineButton
              declineButtonText="Accept Selected"
              onAccept={handleAcceptAll}
              onDecline={handleAccept}
              cookieName="NosVitaleCookies"
            >
              <p style={{color: 'white'}}>This website uses cookies to improve your experience.</p>
              <p style={{fontSize: 13, margin: '5px 0 5px 0', color: 'white'}}>We use essential cookies for the operation of our website. These cookies are necessary for basic functions such as security, network management, and accessibility. You can disable these by changing your browser settings, but this may affect how the website functions.</p>
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="necessary"
                    checked={preferences.necessary}
                    disabled
                  />
                  Necessary
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="statistics"
                    checked={preferences.statistics}
                    onChange={handlePreferenceChange}
                  />
                  Statistics
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="marketing"
                    checked={preferences.marketing}
                    onChange={handlePreferenceChange}
                  />
                  Marketing
                </label>
              </div>
            </CookieConsent>
    )
}

export default CookieBanner;